import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

import imgDanOverview from "../images/dan-about-pic.jpg"
import imgDanBio from "../images/dan-about-bio.jpg"
import imgTheParaskys from "../images/the-paraskys.jpg"
import imgDanRunning from "../images/dan-running.jpg"
import imgMarvelSw from "../images/marvel-sw.jpg"
import imgDaisy from "../images/daisy.jpg"
import imgLilly from "../images/lilly.jpg"
import imgWillow from "../images/willow.jpg"

import "./about.styles.scss"

const contentful = require("contentful")
const contentfulClient = contentful.createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
})

const AboutPage = ({ location }) => {
  const [danCv, setDanCv] = useState(null)

  useEffect(() => {
    contentfulClient
      .getEntries({
        content_type: "link",
        "fields.id": "dan-cv",
      })
      .then(response => {
        setDanCv(response.items[0])
      })
      .catch(console.error)
  }, [])

  return (
    <Layout className="about" pathname={location.pathname}>
      <Seo
        title="About Parasky Flute Studios"
        description="Dan Parasky, owner and instructor at Parasky Flute Studios in the greater Pittsburgh area."
      />
      <section className="about__header">
        <div className="container">
          <h1 className="title is-size-2 is-size-1-desktop">
            Flutist.
            <br />
            Teacher.
            <br />
            Performer.
            <br />
          </h1>
        </div>
      </section>
      <section className="about__intro">
        <div className="container">
          <div className="columns">
            <div className="column is-two-thirds is-flex is-flex-direction-column is-justify-content-center">
              <div className="intro__text intro__text--highlight content is-size-6 container--text">
                <p className="is-size-6-mobile is-size-5-tablet">
                  It is time to elevate what you have been learning in school
                  band class. Individualized attention is the BEST way to
                  improve EVERYTHING about your playing while challenging
                  yourself to make progress successfully. All my school band
                  teachers shaped so much of my education, but my private
                  teacher took the flute to a place that I didn’t even know
                  existed when I was younger.
                </p>
              </div>
              <div className="content is-size-6 container--text">
                <p>Together we will</p>
                <ul>
                  <li>
                    Find a{" "}
                    <span className="has-text-weight-bold is-uppercase">
                      radiant
                    </span>{" "}
                    tone
                  </li>
                  <li>
                    Learn how to make those fingers{" "}
                    <span className="has-text-weight-bold is-uppercase">
                      fly
                    </span>
                  </li>
                  <li>
                    Break down rhythms into{" "}
                    <span className="has-text-weight-bold is-uppercase">
                      easy-
                    </span>
                    to-understand fragments
                  </li>
                  <li>
                    Expose you to{" "}
                    <span className="has-text-weight-bold is-uppercase">
                      tons
                    </span>{" "}
                    of new musical pieces that will challenge and excite you
                  </li>
                </ul>
              </div>
              <div className="intro__text content is-size-6 container--text">
                <p>
                  I have some vivid memories of my early musical life to share
                  with you. What I thought would be a quick find in the local
                  yellow pages (before the internet) took me years to find the
                  right private flute teacher for me.
                </p>
                <p>
                  My elementary school band director, Mr. Weber, sent me home
                  with a contact list of suggested private teachers after my
                  family reached out to him for some help. Some people returned
                  our calls but surprisingly, many people did not even get back
                  to us.
                </p>
                <p>
                  That's when I knew that I wanted to not only become a private
                  teacher myself but make sure that local flutists never had
                  that issue.
                </p>
                <p>
                  We tried a few teachers out, but the personalities just didn’t
                  mesh. There had to be someone out there that was the right
                  fit.
                </p>
                <p>
                  After some trial and error, we did land on someone, but his
                  primary instrument was the saxophone. Something about our
                  relationship clicked and I started regular weekly lessons with
                  Mr. Cacese. He is the person that taught me how to count and
                  understand rhythm.
                </p>
                <p>
                  Something was still wrong though. I progressed very quickly
                  but noticed my flute playing was advancing above Mr. Cacese’s,
                  so the search continued. This was a point in my life that I
                  was so thankful for my middle school band director because he
                  encouraged me to keep working hard on my own while I was
                  looking for a new private instructor.
                </p>
                <p>
                  A few years later and by chance, I was purchasing music in a
                  local store. Another customer noticed what I was buying and
                  asked me if I was studying the flute with anyone. I wasn’t at
                  the time and that random stranger gave me the number of their
                  former teacher - Wendy Kumer.
                </p>
                <p>
                  I reached out to get started in lessons with Wendy and that
                  was when EVERYTHING CHANGED.
                </p>
                <p>
                  Let me be the teacher that you might not have even known you
                  were looking for!
                </p>
                <p className="mt-6">
                  <Link
                    to="/lessons"
                    className="button is-link arrow-right-enlarge"
                  >
                    Book a lesson with me <FaArrowRight className="ml-3" />
                  </Link>
                </p>
              </div>
            </div>
            <div className="column is-one-third">
              <figure className="image mt-2 is-75-percent-mobile-xl">
                <img
                  className="has-border-radius-2 has-box-shadow bio-pic"
                  src={imgDanOverview}
                  alt="Dan Parasky"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="about__formal py-6 has-background-primary-light">
        <div className="container">
          <div className="columns about__bio">
            <div className="column is-half">
              <figure className="image is-75-percent-mobile-xl">
                <img
                  className="has-border-radius-2 has-box-shadow bio-pic"
                  src={imgDanBio}
                  alt="Dan Parasky - Owner, Instructor"
                />
              </figure>
            </div>
            <div className="column">
              <div className="formal__text content is-size-6 container--text">
                <p>
                  Dan Parasky graduated from Boston University with a Master of
                  Music in Music Education and an undergraduate degree in Music
                  Performance from Duquesne University. His private flute
                  students have successfully auditioned for various honor
                  ensembles, soloist competitions and workshops at the local,
                  regional, national, and international level. He is an adjunct
                  faculty member at Duquesne University.
                </p>
                <p>
                  Dan is a flute choir enthusiast that has conducted honors
                  ensembles at both the Mid-Atlantic Flute Convention and the
                  National Flute Convention. Mr. Parasky is serving as the
                  Convention Flute Choir Coordinator with the National Flute
                  Association from 2021-2025 for their annual conventions and
                  helped launch the first ever NFA Flute Ensemble Festival.
                </p>
                <p>
                  Dan is a Wm. S. Haynes Artist and plays on a custom-designed
                  10k/14k flute featuring a 14k N-cut head joint, platinum
                  riser, 10k body with silver mechanism, split E and C# trill
                  key and 14k ribs, posts, and D# roller. He also plays an alto
                  flute and piccolo from the Haynes-Amadeus line.
                </p>
                <a
                  disabled={danCv ? false : true}
                  href={danCv ? danCv.fields.file.fields.file.url : "#"}
                  className="button is-primary is-inverted mt-4 arrow-right-to-external"
                  target="_blank"
                  rel="noreferrer"
                >
                  Check out my full resume <FaArrowRight className="ml-3" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about__extra">
        <div className="container">
          <div className="extra__text content is-size-6 is-flex is-flex-direction-column">
            <h2 className="subtitle has-border-bottom-info-1 is-align-self-flex-start">
              My non-flute life...
            </h2>
            <div className="columns is-flex-wrap-wrap">
              <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between mt-4">
                <p className="pb-4">
                  I am married to my high school sweetheart Jackie. Marching
                  band brought us into each other's lives. We love the outdoors,
                  cooking, and all things about staying organized.
                </p>
                <figure className="image m-2 is-75-percent-mobile-xl">
                  <img
                    src={imgTheParaskys}
                    alt="Dan and Jackie Parasky"
                    className="has-border-radius-2 has-box-shadow"
                  />
                </figure>
              </div>
              <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between mt-4">
                <p className="pb-4">
                  I am an avid runner that came to the sport late in life. I
                  started training about 5 years ago and ran my first full
                  marathon in November 2019 in Las Vegas.
                </p>
                <figure className="image m-2 is-75-percent-mobile-xl">
                  <img
                    src={imgDanRunning}
                    alt="Dan at the Las Vegas Marathon"
                    className="has-border-radius-2 has-box-shadow"
                  />
                </figure>
              </div>
              <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between mt-4">
                <p className="pb-4">
                  Video games are my passion (especially retro 80’s games) but
                  nowadays you’ll find me sneaking in some Destiny after
                  teaching lessons. Honestly, anything nerd culture is my
                  thing...especially Star Wars and the Marvel Cinematic
                  Universe.
                </p>
                <figure className="image m-2 is-75-percent-mobile-xl">
                  <img
                    src={imgMarvelSw}
                    alt="Marvel Studios and Star Wars logos"
                    className="has-border-radius-2 has-box-shadow"
                  />
                </figure>
              </div>
              <div className="the-cats column is-full-mobile is-half-tablet is-one-quarter-desktop is-flex is-flex-direction-column is-justify-content-space-between mt-4">
                <p className="pb-4">
                  3 cats round out our household...meet Willow, Lily and Daisy.
                </p>
                <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                  <figure className="image is-128x128  e m-1">
                    <img
                      src={imgWillow}
                      alt="Willow"
                      className="is-rounded has-box-shadow"
                    />
                  </figure>
                  <figure className="image is-128x128 e m-1">
                    <img
                      src={imgLilly}
                      alt="Lilly"
                      className="is-rounded has-box-shadow"
                    />
                  </figure>
                  <figure className="image is-128x128 m-1">
                    <img
                      src={imgDaisy}
                      alt="Daisy"
                      className="is-rounded has-box-shadow"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about__videos py-6 has-background-grey-lighter">
        <div className="container">
          <div className="columns content">
            <div className="column">
              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/pY35aF9Pn1U?si=1w99j4HkbaZvjlGP"
                  title='"Moonburst" by Robert Traugh'
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="column">
              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/RpNjZTXBDFY?si=SaFLSYoDHV2YrULT"
                  title="Autumn Vespers...Alexandra Molnar-Suhajda"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="video-container">
                <iframe
                  allow="autoplay"
                  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/285252021&color=%23323822&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                  title="Dark Angel...Gay Kahkonen (Live Recital Recording)"
                ></iframe>
              </div>
            </div>
            <div className="column">
              <div className="video-container">
                <iframe
                  allow="autoplay"
                  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1025978200&color=%23323822&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                  title=" Super Mario Bros Theme...Koji Kondo, Alan Silvestri. Arr. Lisa Ochoco"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage
